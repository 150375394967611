import Vue from 'vue'
import axios from 'axios'
// import router from '../router' // importe seu roteador Vue
import store from '../store/index'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://puc.stv.com.br/django/api'
} else {
    axios.defaults.baseURL = 'http://localhost:8000/api'
    // axios.defaults.baseURL = 'https://puc.stv.com.br/django/api'

}

const success = res => res
// const error = res => res
const error = err => {
    console.log('Erro capturado no interceptor:', err);

    if (!err.response) {
        // Caso não haja resposta do servidor (exemplo: problema de conexão)
        store.dispatch('resetSnack')
        store.dispatch("showErrorSnack", "Erro de conexão com o servidor.");
        return Promise.reject(err);
    }

    switch (err.response.status) {
        case 400:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", err.response.data?.non_field_errors || 'Erro na solicitação');
            break;
        case 401:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", "Não Autorizado");
            break;
        case 404:
        case 406:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", err.response.data || "Recurso não encontrado.");
            break;
        case 307:
            store.dispatch('resetSnack')
            store.commit('destroyUser')
            store.dispatch("showErrorSnack", "Sua Sessão Expirou");
            break;
        case 405:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `Sem Permissão para usar o Recurso ${err.config.url}`);
            break;
        case 500:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", "O Servidor não respondeu.");
            break;
        default:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `Erro inesperado: ${err.response.status}`);
            break;
    }

    return Promise.reject(err); // 🔥 Importante! Mantém o erro sendo propagado
};

axios.interceptors.response.use(success, error)


axios.interceptors.request.use(function (config) {
    if (store.getters.getDebugAxiosRequest) {
        console.log(config);
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})